import { createContext, useContext, useState } from "react";

export const UIContext = createContext();

export const useUIContext = () => useContext(UIContext);


export const UIProvider = ({ children }) => {
  // Move the useState hook inside the component
  const [home, setHome] = useState('/home');

  const value = {
    home,
    setHome,
  };

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
};
