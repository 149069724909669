import React from 'react'
import Header from '../../components/Header'
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from '../../components/Footer';
import targetIcon from '../../assets/images/icons/target.png';

const EmployeTarget = () => {
    const modifiedItem = {
        index: 1,
        icon: targetIcon,
        label: "Target",
        link: "/employe/target",
    };
  return (
    <>
    
    <Header title="Target" arrowIcon={arrowIcon} />
    
    <div className="ContentContainer"></div>
    <Footer modifiedItem={modifiedItem} />
    </>
  )
}

export default EmployeTarget