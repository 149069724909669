import React from "react";
import "../../assets/css/admin/followuphistory.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import Header from "../../components/Header";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';


const FollowUpHistory = () => {
    const modifiedItem = {
      index: 1,
      icon: requestIcon,
      label: " History",
      link: "/admin/followup/history",
    };
    const status='In-Process'
    return (
      <>
      <Header title="Followup History" arrowIcon={arrowIcon} />
        <div className="ContentContainer">
          
  
          <div className="FollowUpHeader mt-3 d-flex justify-content-between">
              <h4>Total Followup : <span>1</span> </h4>
              
              <span className={`CardCount ${status === 'In-Process' ? 'Colorblue' : status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
          </div>

  
          <div className="TrackDetails  FollowUpHistory">
          <span className="CardCount">1</span>
 
              <div className="row gy-3 pt-3">
                  <div className="col-12 mt-0">
                      <div className="TrackMessageCard">
                        <h4 className="FollowupHistoryIssue">Category : <span>Message Delivery Related Issue</span></h4>
                      </div>
                  </div>
                <div className="col-6 pe-0">
                    <div className="TrackMessageCard">
                    <h4 >Ticket ID </h4>
                    <span>MIS-11102024-39246</span>
                    </div>
                </div>
                  <div className="col-6 text-end ps-0">
                      <div className="TrackMessageCard">
                        <h4>Ticket Date </h4>
                        <span>03-10-2024 04:04:56</span>
                      </div>
                  </div>
                  <div className="col-6 pe-0">
                      <div className="TrackMessageCard">
                        <h4>Followuped By </h4>
                        <span>Nikhil</span>
                      </div>
                  </div>
                  <div className="col-6 text-end ps-0">
                      <div className="TrackMessageCard">
                        <h4>Followuped Date </h4>
                        <span>11-10-2024</span>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard FallowCard">
                          <h4>Customer Remarks : </h4>
                          <textarea name="comment" id="comment" value='Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem placeat libero mollitia illum officiis impedit!' placeholder="Lorem ipsum dolor sit amet." readOnly> </textarea>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard FallowCard">
                          <h4> Followup Remarks : </h4>
                          <textarea name="comment1" id="comment1" placeholder="Lorem ipsum dolor sit amet." readOnly></textarea>
                      </div>
                  </div>
                  <div className="col-12">
                    <div className="TrackMessageCard  PlayVoiceContain">
                        <button className="PlayVoiceBtn RequestModalBtn"><PlayArrowIcon/> CRE Voice </button>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <Footer modifiedItem={modifiedItem} />
      </>
    );
};
export default FollowUpHistory