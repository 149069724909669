import React from "react";
import "../../assets/css/admin/todorequest.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import ticketIcon from "../../assets/images/icons/ticket.png";
import RadioButtonGroup from "../../components/RadioGroup";
import Header from "../../components/Header";
import DashboardCards from "../../components/DashboardCard";
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from "react-router-dom";
const ToDoRequest = () => {
  const [open, setOpen] = React.useState(false);
  const modifiedItem = {
    index: 1,
    icon: requestIcon,
    label: "To-Do Request",
    link: "/admin/todorequest",
  };
  const ticketData = [
    { count: 10, label: 'Total', className: '' },
    { count: 4, label: 'Pending', className: ' pending' },
    { count: 6, label: 'Resolved', className: ' resolve' },
  ];
  const status='In-Process'
  return (
    <>
    <Header title="To-Do Request" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        
        
      <DashboardCards title="Tickets Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData} />

        <div className="TrackRequest mt-3">
          <div className="TrackRequestHeader">
            <RadioButtonGroup options={["thisMonth", "lastMonth", "all"]} initialOption="thisMonth"/>
          </div>
        </div>

        <div className="TrackDetails RequestDetails">
            <span className={`CardCount ${status === 'In-Process' ? 'Colorblue' : status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
            <div className="row gy-3">
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <h4>Ticket Id :  <span>MSI-1010</span></h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <h4>Date :  <span>03-10-2024 04:04:56</span></h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <h4>Category :  <span>Message Delivery Related Issue</span></h4>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard MessageCard">
                        <h4>Remarks :</h4>
                        <p>Not Working</p>
                    </div>
                </div>
                <div className="col-12">
                    <div className="TrackMessageCard">
                        <Link to="/admin/followup"><button className="RequestModalBtn">Action </button></Link>
                        
                    </div>
                </div>
                <div className={`RequestModalContain ${open === false ? 'd-none' : ''}`}>
                <div className="RequestModal">
                  <h4 className="CardTitle">Contact Details <button className="CloseBtn" onClick={() => setOpen(!open)}><CloseIcon/></button></h4>
                  <ul>
                    <li><a href="mailto:cs.team@manthanitsolutions.com" target="_blank" className="Word_breack"><MailIcon/> cs.team@manthanitsolutions.com</a></li>
                    <li><a href="tel:18001370808"><CallIcon/> 1800-137-0808</a></li>
                  </ul>
                </div>
                </div>
            </div>
        </div>
      </div>
      <Footer modifiedItem={modifiedItem} />
    </>
  );
};

export default ToDoRequest;
