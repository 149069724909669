import React, { useState } from "react";
import "../../assets/css/employe/chatlist.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
// import linkIcon from "../../assets/images/icons/link.png";
import employesIcon from "../../assets/images/icons/employes.png";
import { Avatar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const ChatList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  // Sample data for employees
  const employees = [
    { id: 1, name: "Nikhil" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Ramkesh" },
    { id: 4, name: "Ajit" },
    { id: 5, name: "Dheeraj" },
    { id: 6, name: "Jane Smith" },
    { id: 7, name: "Girija" },
    { id: 8, name: "Yogesh" },
  ];

  const handleEmployeeClick = (id, name) => {
    navigate(`/employe/chat/${id}`, { state: { employeeName: name, id } });
  };

  // Filter employees based on the search term
  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getLastMessage = (id) => {
    const storedMessages = JSON.parse(localStorage.getItem('messages')) || {};
    return storedMessages[id] ? storedMessages[id][storedMessages[id].length - 1]?.text : 'No messages yet.';
  };

  return (
    <>
      <Header title="Chat List" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <SearchIcon className="search-icon" />
        </div>
        <ul className="employee-list">
          {filteredEmployees.map((employee) => (
            <li
              key={employee.id}
              onClick={() => handleEmployeeClick(employee.id, employee.name)}
              className="employee-item"
            >
              <Avatar>{employee.name.charAt(0)}</Avatar>
              <div className="employee-info">
                <div className="employee-name">{employee.name}</div>
                <div className="employee-last-message">{getLastMessage(employee.id)}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Footer modifiedItem={{ index: 1, icon: employesIcon, label: "Chat List", link: "/employe/chatlist" }} />
    </>
  );
};

export default ChatList;
