import React from 'react';
import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { apiCall } from "../services/apiservices";
import { useUIContext } from '../context';
import { Link } from 'react-router-dom';

const DashboardCards = ({ title, invoiceIcon, ticketData }) => {
  // Check if the current URL is "/message"
  const isMessagePage = window.location.pathname.includes('/trackmessage');

  const [customerdata, setcustomerdata] = useState([]);
  const [count, setcount] = useState([]);


  const [todaysub, settodaysub] = useState('')
  const [todaydel, settodaydel] = useState('')
  const [todayfail, settodayfail] = useState('')

  const [loading, setloading] = useState(false);


  useEffect(() => {
    logoutfunc();
  }, [])


  const logoutfunc = () => {
    try {

      var account_id = localStorage.getItem('account_id');
      var portal_name = localStorage.getItem('portal_name');

      if (account_id && portal_name) {
        localStorage.removeItem('account_id')
        localStorage.removeItem('portal_name')
      }


    } catch (err) {

    }
  }


  useEffect(() => {
    fetchdata();
  }, [])

  let fetchdata = async () => {
    try {
      // const token = localStorage.getItem('token');
      // if (token) {
        // const parsedToken = jwtDecode(token);

        // let customer_id = parsedToken.customerID
        // let payloadata = { 'customer_id': customer_id }

        let response = await apiCall({ endpoint: "track-message/getdata", method: 'GET' })

        let cust_id = response.data.map(item => ({
          cust: item.account_id,
          account_title: item.account_title,
          source_name: item.source_name
        }))
        setcustomerdata(cust_id)
      // }
    } catch (err) {

      console.log(err)

    }
  }

  const handledata = async (e) => {
    var account_title = e.target.value;
    try {

      var account_id = document.getElementById('account_id');
      var source_name = document.getElementById('portal_name');

      account_id.value = '';
      source_name.value = '';

      // Clear localStorage values
      localStorage.removeItem('account_id');
      localStorage.removeItem('portal_name');

      const endpoint = `track-message/getaccount?account_title=${account_title}`
      // let payloadata = { 'account_title': account_title }

      let response = await apiCall({ endpoint: endpoint, method: 'GET'})




      if (response.data) {

        account_id.value = response.data[0].account_id;
        source_name.value = response.data[0].source_name;

        localStorage.setItem('account_id', response.data[0].account_id);
        localStorage.setItem('portal_name', response.data[0].source_name);

        setloading(true)

        // let newpayload = { 'account_id': account_id.value, 'source_name': source_name.value }
        let countdata_1 = await apiCall({ endpoint: `track-message/getcount?account_id=${account_id.value}&source_name=${source_name.value}`, method: 'GET'})

        settodaysub(countdata_1.data.todaysub)
        settodaydel(countdata_1.data.todaydel)
        settodayfail(countdata_1.data.todayfailed)
        if (countdata_1.data.todaysub != "") {
          setloading(false)
        }


        // let countdata_d = countdata_1.data.map(item => ({
        //   submission: item.todaysub,
        //   delivery: item.todaydel,
        //   todayfailed: item.todayfailed
        // }))

        // console.log(countdata_d,'dsf')

        // setcount(countdata_d)

      }

    } catch (err) {
      console.log(err)
    }
  }


  const {home} = useUIContext();




  return (
    <>
      <div className="HelpSupportHeader">
        {!isMessagePage ? <>
          <div className={` ${isMessagePage ? 'portalContain' : ' '}`}>
            <h4>
              <img src={invoiceIcon} alt="icon" /> {title}
            </h4>
            <div className={`Trackselect ${isMessagePage ? '' : 'd-none'}`}>
              <select name="id" id="id" onChange={handledata}>
                <option value="">Select ID</option>
                {customerdata.length > 0 ? customerdata.map((item, index) => (
                  <option key={index}>{item.account_title}</option>
                )) : ('hello')}
              </select>
            </div>
          </div>
          <div className="row">
            {ticketData.map((item, index) => (
              <div className="col-4" key={index}>
                <div className={`HelpSupportDCard ${item.className}`}>
                  <span className="TicketCount">{item.count}</span>
                  {home === '/employe/home' ? (
                    <Link to={`/employee/${item.label}`}><h4>{item.label}</h4></Link>
                  ) : (
                   <h4>{item.label}</h4>
                   )}
                </div>
              </div>
            ))}
          </div>
        </>

          :

          <>

            <div className={`loader ${loading ? '' : 'd-none'}`}>
              <div className="spinner"></div>
              <p>Loading...</p>
            </div>

            <div className={` ${isMessagePage ? 'portalContain' : ' '}`}>
              <h4>
                <img src={invoiceIcon} alt="icon" /> {title}
              </h4>
              <div className={`Trackselect ${isMessagePage ? '' : 'd-none'}`}>
                <select name="id" id="id" onChange={handledata}>
                  <option value="">Select ID</option>
                  {customerdata.length > 0 ? customerdata.map((item, index) => (
                    <option key={index}>{item.account_title}</option>
                  )) : ('hello')}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-4 pe-0">
                <div className='HelpSupportDCard'>
                  <span className="TicketCount">{todaysub || 0}</span>
                  <h4>Submission</h4>
                </div>
              </div>

              <div className="col-4">
                <div className='HelpSupportDCard resolve'>
                  <span className="TicketCount">{todaydel || 0}</span>
                  <h4>Delivered</h4>
                </div>
              </div>

              <div className="col-4 ps-0">
                <div className='HelpSupportDCard pending'>
                  <span className="TicketCount">{todayfail || 0}</span>
                  <h4>Undelivered</h4>
                </div>
              </div>
            </div>

          </>

        }
      </div>
    </>
  );
};

export default DashboardCards;
