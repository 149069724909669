import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import pdfIcon from '../assets/images/icons/pdf.png';
import pdfIcon2 from '../assets/images/icons/pdf2.png';

function createData(number, date, status) {
  return {
    number,
    date,
    status,
  };
}

function Row(props) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} 
      className={row.status === 'Close' ? 'bggreen' : 'bgred'}
      >
        <TableCell align="center">
          {row.number}
        </TableCell>
        <TableCell align="center">{row.date}</TableCell>
        <TableCell align="center"><button className='pdfIcon'>
          <img src={row.status === 'Close' ? pdfIcon2 : pdfIcon} alt="img" />
          </button></TableCell>
        <TableCell align="center">{row.status}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    number: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

const rows = [
  createData(10101, '11-01-2021', 'Open'),
  createData(10102, '11-02-2021', 'Open'),
  createData(10103, '15-02-2021', 'Close'),
];

export default function InvoiceTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Invoice Number</TableCell>
            <TableCell align="center">Invoice Date</TableCell>
            <TableCell align="center">PDF</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.number} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
