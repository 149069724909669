import React, { useState, useRef, useEffect } from 'react';
import { useLocation ,useNavigate} from 'react-router-dom';
import "../../assets/css/customer/bot.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from "../../assets/images/icons/send.png";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import Header from '../../components/Header';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [image, setImage] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const chatWindowRef = useRef(null);
  const location = useLocation();
  const chatId = location.state?.id; // Safely access id
  const mediaRecorderRef = useRef(null);

  // Check for chatId and redirect if not available

  const navigate = useNavigate();
  useEffect(() => {
    if (!chatId) {
      navigate("/employe/chatlist"); // Redirect programmatically
      return; // Prevent further execution
    }
    const storedMessages = JSON.parse(localStorage.getItem('messages')) || {};
    setMessages(storedMessages[chatId] || []);
  }, [chatId, navigate]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (input.trim() || image || audioBlob) {
      const timestamp = new Date().toISOString();
      const newMessage = {
        text: input,
        image: image ? URL.createObjectURL(image) : null,
        audio: audioBlob ? URL.createObjectURL(audioBlob) : null,
        sender: 'user',
        timestamp,
        read: false,
      };

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, newMessage];
        const storedMessages = JSON.parse(localStorage.getItem('messages')) || {};
        storedMessages[chatId] = updatedMessages; // Update messages for the specific chatId
        localStorage.setItem('messages', JSON.stringify(storedMessages));
        return updatedMessages;
      });

      setInput('');
      setImage(null);
      setAudioBlob(null);

      // Simulate bot response
      setTimeout(() => {
        const botResponse = generateBotResponse(input);
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages, botResponse];
          const storedMessages = JSON.parse(localStorage.getItem('messages')) || {};
          storedMessages[chatId] = updatedMessages; // Update bot response in localStorage
          localStorage.setItem('messages', JSON.stringify(storedMessages));
          return updatedMessages;
        });
      }, 1000);
    }
  };

  const generateBotResponse = (input) => {
    const timestamp = new Date().toISOString();
    let textResponse = input ? `You said: "${input}".` : `How can I help you?`;
    return {
      text: textResponse,
      sender: 'bot',
      timestamp,
      read: true,
    };
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleVoiceRecord = async () => {
    if (isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.start();

      const audioChunks = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks);
        setAudioBlob(audioBlob);
      };

      setIsRecording(true);
    }
  };

  const Message = ({ text, image, audio, sender, timestamp, read }) => (
    <div className={sender === 'user' ? 'BotUserMessage' : 'BotMessage'}>
      {image && <img src={image} alt="Uploaded" className='BotImageMessage' />}
      {text && <div className='BotMessageText Word_breack'>{text}</div>}
      {audio && (
        <div>
          <audio controls>
            <source src={audio} type="audio/wav" />
            Your browser does not support the audio tag.
          </audio>
        </div>
      )}
      <div className='BotMessageFooter'>
        <span className='BotTimestamp'>
          {timestamp ? new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ''}
        </span>
        {sender === 'user' && read ? (
          <div className='BotCheckIcons'>
            <DoneAllIcon className='BotCheckIcon' />
            <CheckCircleTwoToneIcon className='BotCheckIcon' />
          </div>
        ) : sender === 'user' && !read ? (
          <DoneAllIcon className='BotCheckIcons' />
        ) : null}
      </div>
    </div>
  );

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const employeeName = location.state?.employeeName || 'Chat';

  return (
    <>
      <Header title={employeeName} arrowIcon={arrowIcon} />
      <div className="ContentContainer BotChatApp">
        <div ref={chatWindowRef} className='BotChatWindow'>
          {messages.map((msg, index) => (
            <Message
              key={index}
              text={msg.text}
              image={msg.image}
              audio={msg.audio}
              sender={msg.sender}
              timestamp={msg.timestamp}
              read={msg.read}
            />
          ))}
        </div>
        <form onSubmit={handleSendMessage} className='BotMessageInput'>
          <label htmlFor="file-upload" className='BotUploadLabel'>
            <AttachFileIcon className='BotIcon' />
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            className='BotFileInput'
            onChange={handleUpload}
          />
          <MicIcon
            className='BotIcon'
            onClick={handleVoiceRecord}
            style={{ color: isRecording ? 'red' : 'black' }}
          />
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className='BotInput'
          />
          <button type="submit" className='BotSendButton'>
            <img src={SendIcon} alt="icon" className='BotSendIcon' />
          </button>
        </form>
      </div>
    </>
  );
};

export default Chat;
