import React, { useState, useEffect } from "react";
import "../../assets/css/customer/profile.css";
import editIcon from "../../assets/images/icons/edit.png";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import Profile1Icon from "../../assets/images/icons/profile1.png";
import eyeIcon from '../../assets/images/icons/eye.png';
import Header from "../../components/Header";
import { jwtDecode } from 'jwt-decode';
import { apiCall } from "../../services/apiservices";
import CustomSnackbar from '../../components/snackBar';
import { ClipLoader } from 'react-spinners';

const Profile = () => {
  const modifiedItem = {
    index: 1,
    icon: Profile1Icon,
    label: "Profile",
    link: "/profile",
  };

  // State variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [profiledata, setprofiledata] = useState([]);
  const [SelectImage, setSelectedImage] = useState(null);
  const [contactPerson, setContactPerson] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state

  const showSnackbar = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const logoutfunc = () => {
    try {
      const tokendata = localStorage.getItem('token');
      if (tokendata) localStorage.removeItem('token');
    } catch (err) {}
  };

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      showSnackbar("New password and Confirm password do not match.", "error");
      return;
    }

    try {
      // const token = localStorage.getItem('token');
      // if (token) {
      //   const parsedToken = jwtDecode(token);
      //   const customer_id = parsedToken.customerID;
        if (!oldPassword) {
          showSnackbar("Old password is required.", "error");
          return;
        }

        const payload = { oldPassword, newPassword};
        const response = await apiCall({
          endpoint: "auth/updatepassword",
          method: 'post',
          payload,
        });

        if (response.success) {
          showSnackbar("Password updated successfully!");
          logoutfunc();
          window.location.href = '/';
        } else {
          showSnackbar(response.msg || "Old password is wrong", "error");
        }
      // } else {
      //   showSnackbar("Token is missing. Please log in again.", "error");
      // }
    } catch (error) {
      console.error("Error updating password:", error);
      showSnackbar("An error occurred while updating the password. Please try again.", "error");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        uploadProfileImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadProfileImage = async (file) => {
    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append('file', file);

    // const token = localStorage.getItem('token');
    // if (token) {
    //   const parsedToken = jwtDecode(token);
    //   const customer_id = parsedToken.customerID;
    //   formData.append('customer_id', customer_id);

      try {
        const response = await apiCall({
          endpoint: "auth/uploadprofile",
          method: 'post',
          payload: formData,
        });

        if (response) {
          setSelectedImage(response.filePath);
          showSnackbar('Profile image uploaded successfully');
        } else {
          showSnackbar('Failed to upload profile image', 'error');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        showSnackbar('Error uploading image', 'error');
      } finally {
        setLoading(false); // Stop loading
      }
    // } else {
    //   showSnackbar('No token found', 'error');
    //   setLoading(false); // Stop loading
    // }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    // const token = localStorage.getItem('token');
    // if (token) {
    //   const parsedToken = jwtDecode(token);
    //   const customer_id = parsedToken.customerID;
    //   const payloadata = { 'customer_id': customer_id };

      const response = await apiCall({ endpoint: "auth/profiledata", method: 'GET'});

      const profileData = response.data.map(item => ({
        customer_name: item.customer_name,
        pan_card_number: item.company_pan,
        gst_number: item.company_gst_number,
        city: item.city,
        state: item.state,
        company_address: item.company_address,
        company_name: item.company_name,
        customer_profile: item.customer_profile
      }));

      setprofiledata(profileData);
      setContactPerson(response.data[0].contact_person);
      setSelectedImage(response.data[0].customer_profile);
    // }
  };

  return (
    <>
      <Header title="Manage Profile" arrowIcon={arrowIcon} />

      <div className="ContentContainer">
        <section className="ProfileContain">
          <div className="Profile_image">
            <div className="ImgP">
              <img src={SelectImage} alt="Profile" />
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="file-input"
            />
            <label htmlFor="file-input" className="EditProfile" style={{ cursor: 'pointer' }}>
              <img src={editIcon} alt="Edit" />
            </label>
          </div>

          {profiledata.length > 0 ? profiledata.map((item, index) => (
            <h4 key={index} className="CmpnyTitle">{item.company_name}</h4>
          )) : ('')}
          
          {profiledata.length > 0 ? profiledata.map((item, index) => (
            <div key={index} className="Profile_content">
              <div className="ContentCard">
                <div className="row gy-4 py-2">
                  <div className="col-6">
                    <div className="Pcontent_card">
                      <h4>User Name</h4>
                      <p>{item.customer_name}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="Pcontent_card">
                      <h4>Contact Person</h4>
                      <p>{contactPerson}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="Pcontent_card">
                      <h4>Pan</h4>
                      <p>{item.pan_card_number}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="Pcontent_card">
                      <h4>GST Number</h4>
                      <p>{item.gst_number}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="Pcontent_card">
                      <h4>City</h4>
                      <p>{item.city}</p>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="Pcontent_card">
                      <h4>State</h4>
                      <p>{item.state}</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="Pcontent_card">
                      <h4>Billing Address</h4>
                      <p>{item.company_address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )) : ('')}

          <div className="PasswordChange">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Privacy and Security
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse " data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className="row gy-4 py-2">
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <h4>Old Password</h4>
                          <div className="PinputContain">
                            <input placeholder='Enter old password' type={showOldPassword ? 'text' : 'password'} value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)} />
                            <button className='ShowPasswordbtn' onClick={() => setShowOldPassword(!showOldPassword)}><img src={eyeIcon} alt="icon" /></button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <h4>New Password</h4>
                          <div className="PinputContain">
                            <input placeholder='Enter new password' type={showNewPassword ? 'text' : 'password'} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            <button className='ShowPasswordbtn' onClick={() => setShowNewPassword(!showNewPassword)}><img src={eyeIcon} alt="icon" /></button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <h4>Confirm Password</h4>
                          <div className="PinputContain">
                            <input placeholder='Confirm Password' type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            <button className='ShowPasswordbtn' onClick={() => setShowConfirmPassword(!showConfirmPassword)}><img src={eyeIcon} alt="icon" /></button>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="Pcontent_card">
                          <button className='PasswordChangebtn' onClick={handlePasswordUpdate}>Update</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Spinner */}
        {loading && (
          <div className="spinnerOverlay">
            <ClipLoader loading={loading} size={50} />
          </div>
        )}
      </div>
      <Footer modifiedItem={modifiedItem} />
      <CustomSnackbar 
        open={snackbarOpen} 
        handleClose={handleSnackbarClose} 
        message={snackbarMessage} 
        severity={snackbarSeverity} 
      />
    </>
  );
};

export default Profile;
