import React from "react";
import "../../assets/css/admin/admintrackmessage.css";
import arrowIcon from "../../assets/images/icons/arrow.png";
import Footer from "../../components/Footer";
import Profile1Icon from "../../assets/images/icons/profile1.png";
import SearchIcon from "../../assets/images/icons/search.png";
import Header from "../../components/Header";
import { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { apiCall } from "../../services/apiservices";
import DashboardCards from "../../components/DashboardCard";
import messageIcon from "../../assets/images/icons/message.png";
import messageIcon2 from "../../assets/images/icons/message2.png";

const AdminTrackMessage = () => {
    const modifiedItem = {
        index: 1,
        icon: Profile1Icon,
        label: "Track ",
        link: "/trackmessage",
    };

    const [customerdata, setcustomerdata] = useState([]);
    const [userId, setuserId] = useState('');
    const [mobileNo, setmobileNo] = useState('');
    const [carddata, setcarddata] = useState([]);
    const [loading, setloading] = useState(false);
    const [nodatafound, setnodatafound] = useState('');
    const [source_name, setsource_name] = useState('');
    const [account_id, setaccount_id] = useState('');

    const [accountId, setAccountId] = useState('');

    useEffect(() => {
        fetchdata();
    }, [])

    // --------------------------  function to get drop down data ----------------------------
    let fetchdata = async () => {
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const parsedToken = jwtDecode(token);

                let customer_id = parsedToken.customerID
                let payloadata = { 'customer_id': customer_id }

                let response = await apiCall({ endpoint: "getdata", method: 'POST', payload: payloadata })

                let cust_id = response.data.map(item => ({

                    cust: item.account_id
                }))
                setcustomerdata(cust_id)
            }
        } catch (err) {

            console.log(err)

        }

    }

    // ---------------------------------- function to seach sms through number on click -----------------------------------
    const Searchdata = async () => {
        try {

            var account_idd = localStorage.getItem('account_id');

            if (!account_idd) {
                return alert('Please select UserId')

            }
          
            if (mobileNo.trim() === '') {
                return alert('Please enter a valid  mobile no')
            }
            if (mobileNo.length < 9) {

                return alert('Please enter a valid  mobile no')
            }

            const account_id = localStorage.getItem('account_id');
            const portal_name = localStorage.getItem('portal_name');

            document.getElementById('account_id').value = account_id;
            document.getElementById('portal_name').value = portal_name;


            let payload = {
                'mobileno': mobileNo,
                'account_id': account_id,
                'portal_name': portal_name
            }


            setloading(true)
            let response = await apiCall({ endpoint: "api/getstatusdata", method: 'post', payload: payload })

            if (response.data === 209) {

                setloading(false)
                setnodatafound('No Data Found')
                setcarddata([])

            } else {



                let cardfinaldata = response.data.map(item => ({

                    mobile: item.Mobile,
                    error_code: item.Error_Code,
                    accountType: item.Account_Type,
                    smsType: item.SMS_Type,
                    status: item.Status,
                    Unit: item.Unit,
                    message: item.sms,
                    submitdata: item.Submit_Date,
                    delivereddata: item.Delivered_Date,

                }))
                setcarddata(cardfinaldata)
                setloading(false)
                setnodatafound('')

            }
        } catch (err) {
            console.log(err)
        }

    }


    const ticketData = [
        { count: 100, label: 'Submission', className: '' },
        { count: 40, label: 'Delivered', className: 'resolve' },
        { count: 60, label: 'Undelivered', className: ' pending' },
    ];

    return (
        <>
            <Header title="Track Message" arrowIcon={arrowIcon} />

            <div className={`loader ${loading ? '' : 'd-none'}`}>
                <div className="spinner"></div>
                <p>Loading...</p>
            </div>

            <div className="ContentContainer">


                <DashboardCards title="SMS Dashboard" invoiceIcon={messageIcon2} ticketData={ticketData} />

                <div className="TrackHeader">
                    <div className="TrackTitle"><img src={messageIcon} alt="icon" />Search Message</div>
                    <input type="hidden" id="account_id" value='' />
                    <input type="hidden" id="portal_name" value='' />
                    <div className="Trackinput TrackMobile">
                        <input type="text" placeholder=" Mobile No." value={mobileNo} onChange={((e) => setmobileNo(e.target.value))} />
                        <button onClick={Searchdata}><img src={SearchIcon} alt="icon" /></button>
                    </div>
                </div>



                <div className="AccountLinkCardContain">
                {carddata.length > 0 ? carddata.map((item, index) => (
                    <div className="TrackDetails">
                        <span className="CardCount">{index + 1}</span>
                        <div className="row gy-3">
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>Sent Date </h4>
                                    <p>{item.submitdata}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>Delivered Date </h4>
                                    <p>{item.delivereddata}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>Mobile </h4>
                                    <p>{item.mobile}</p>
                                </div>
                            </div>
                            <div className="col-6 ">
                                <div className="TrackMessageCard">
                                    <h4>Unit</h4>
                                    <p>{item.Unit}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>MSG Type</h4>
                                    <p>{item.smsType}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>Type</h4>
                                    <p>{item.accountType}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>Error Code</h4>
                                    <p>{item.error_code}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="TrackMessageCard">
                                    <h4>Status</h4>
                                    <p>{item.status}</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="TrackMessageCard MessageCard">
                                    <h4>Message </h4>
                                    <p>{item.message}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )) : ('')}
                </div>

                <p className="text-center mt-5">{nodatafound}</p>


            </div>
            <Footer modifiedItem={modifiedItem} />
        </>
    );
};

export default AdminTrackMessage;
