import React, { useEffect, useState } from "react";
import "../../assets/css/customer/trackrequest.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import requestIcon from "../../assets/images/icons/request.png";
import ticketIcon from "../../assets/images/icons/ticket.png";
import RadioButtonGroup from "../../components/RadioGroup";
import Header from "../../components/Header";
import DashboardCards from "../../components/DashboardCard";
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { apiCall } from "../../services/apiservices";
import { jwtDecode } from 'jwt-decode';


const TrackRequest = () => {

  const [open, setOpen] = React.useState(false);
  const [getTotalCountData,setCountData] = useState(0);
  const [getOpenCountData,setOpenCountData] = useState(0);
  const [getClosedCountData,setClosedCountData] = useState(0);
  const [AccountDate,setAccountDate] = useState([]);
  const [selectedDataValue,setSelectedValue] = useState([]);

  useEffect(()=>{

    const getDataForPageOnload = async () =>{

      // const token = localStorage.getItem('token');
      // const parsedToken = jwtDecode(token);
      // const customer_id = parsedToken.customerID;

      const selectedValue = document.querySelector('input[name="radio"]:checked').value;
      
      let endpoint = `myRequest/getDataOnloadPageForTrackRequest?radioBtnValue=${selectedValue}`;

      let response = await apiCall({endpoint:endpoint,method:'GET'})

      if(response.success){

        setCountData(response.data.totalCount);
        setOpenCountData(response.data.openCount);
        setClosedCountData(response.data.closeCount);
        let accountData = response.data.ticketData.map(item => ({
          ticketId: item.ticket_id,
          createdDate: item.create_date,
          categoryType: item.issue_type,
          remarks: item.comment,
          status: item.status
      }));
        setAccountDate(accountData);
      }else{}

    }

    getDataForPageOnload();    

  },[selectedDataValue]);

  const handleRadioChange = (value) => {
    setSelectedValue(value);
  };

  const modifiedItem = {
    index: 1,
    icon: requestIcon,
    label: "My Request",
    link: "/trackrequest",
  };
  const ticketData = [
    { count: `${getTotalCountData}`, label: 'Total', className: '' },
    { count: `${getOpenCountData}`, label: 'Pending', className: 'pending' },
    { count: `${getClosedCountData}`, label: 'Resolved', className: 'resolve' },
  ];

  return (
    <>
    <Header title="Track Your Request" arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        
        
      <DashboardCards title="Tickets Dashboard" invoiceIcon={ticketIcon} ticketData={ticketData} />

        <div className="TrackRequest mt-3">
          <div className="TrackRequestHeader">
            <RadioButtonGroup options={["thisMonth", "lastMonth", "all"]} initialOption="thisMonth" onChange={handleRadioChange}/>
          </div>
        </div>
        <div className="AccountLinkCardContain">
        {AccountDate.length > 0 ? AccountDate.map((item, index) => (
        
          <div className="TrackDetails RequestDetails">
              <span className={`CardCount ${item.status === 'In-Process' ? 'colorRed' : 'ColorGreen'}`}>{item.status}</span>
              <div className="row gy-3">
                  <div className="col-12">
                      <div className="TrackMessageCard">
                          <h4>Ticket Id :  <span>{item.ticketId}</span></h4>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard">
                          <h4>Date :  <span>{item.createdDate}</span></h4>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard">
                          <h4>Category :  <span>{item.categoryType}</span></h4>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard MessageCard">
                          <h4>Remarks :</h4>
                          <p>{item.remarks}</p>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard">
                          <button className="RequestModalBtn" onClick={() => setOpen(!open)}>Contact Us </button>
                      </div>
                  </div>
                  <div className={`RequestModalContain ${open === false ? 'd-none' : ''}`}>
                  <div className="RequestModal">
                    <h4 className="CardTitle">Contact Details <button className="CloseBtn" onClick={() => setOpen(!open)}><CloseIcon/></button></h4>
                    <ul>
                      <li><a href="mailto:cs.team@manthanitsolutions.com" target="_blank"  rel="noreferrer" className="Word_breack"><MailIcon/> cs.team@manthanitsolutions.com</a></li>
                      <li><a href="tel:18001370808"><CallIcon/> 1800-137-0808</a></li>
                    </ul>
                  </div>
                  </div>
              </div>
          </div>

        )) : ('')}
        </div>

      </div>
      <Footer modifiedItem={modifiedItem} />
    </>
  );
};

export default TrackRequest;
