import React from 'react'
import "../../assets/css/customer/signup.css";
import Logo from '../../assets/images/logo.png'
import seePasswordIcon from '../../assets/images/icons/eye.png'
import hidePasswordIcon from '../../assets/images/icons/eye1.png'
import { Link } from 'react-router-dom';

const SignUp = () => {
    const [open, setOpen] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState(false);
  return (
    <>
    <div className="SignUpContain">
        <div className="SignUpLogo">
            <img src={Logo} alt="img" />
        </div>
        <div className="SignUpCard">
            <div className="SignUpTitle">
                <h4>Sign Up</h4>
                <p>Welcome To Manthan App.</p>
                <p>Manthan support team is just a tap away!</p>
            </div>
            <div className="SignUpGroup">
                <label htmlFor="pan">PAN Number</label>
                <input type="text" placeholder='Enter PAN Number'/>
            </div>
            <div className="SignUpGroup">
                <label htmlFor="id">User ID</label>
                <input type="text" placeholder='Enter Your ID'/>
            </div>
            <div className="SignUpGroup">
                <label htmlFor="id">User Name</label>
                <input type="text" placeholder='Enter Your Name'/>
            </div>
            <div className="SignUpGroup">
                <label htmlFor="Password">Password</label>
                <div className="PasswrdInpt">
                <input type={open === false ? 'password' : 'text'} placeholder=' Password'/>
                <button onClick={() => setOpen(!open)}><img src={open === false ? seePasswordIcon : hidePasswordIcon} alt="icon" /></button>
                </div>
            </div>
            <div className="SignUpGroup">
                <label htmlFor="Password">Confirm Password</label>
                <div className="PasswrdInpt">
                <input type={confirmPassword === false ? 'password' : 'text'} placeholder='Confirm Password'/>
                <button onClick={() => setConfirmPassword(!confirmPassword)}><img src={confirmPassword === false ? seePasswordIcon : hidePasswordIcon} alt="icon" /></button>
                </div>
            </div>
            <div className="SignUpGroup SignUpBtn">
                <Link >
                <button type='submit'>Sign Up</button>
                </Link>
            </div>
            <div className="SignUpDivider"><span>OR</span></div>
            <div className="SignUpGroup signUp">
                <p>Already have an account? <Link to='/'>Login </Link></p>
            </div>
        </div>
    </div>
        <div className="SignUpFooter">
            <p>POWERED BY : <span>MANTHAN IT SOLUTIONS</span> </p>
        </div>
        </>
  )
}

export default SignUp