// RadioButtonGroup.js
import React, { useState } from 'react';

const RadioButtonGroup = ({ options, initialOption, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(initialOption);

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (onChange) {
      onChange(value); // Call the parent's onChange if provided
    }
  };
//use this in page if you need there   const handleSelectionChange = (value) => {
//     console.log("Selected option in Page 2:", value);
//   };

  return (
    <div className="TrackCard">
      {options.map((option) => (
        <div className="TrackBtn" key={option}>
          <input
            type="radio"
            name="radio"
            value={option}
            checked={selectedOption === option}
            onChange={handleChange}
            id={option}
          />
          <label htmlFor={option}>
            {option.charAt(0).toUpperCase() + option.slice(1).replace("Month", " Month")}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
