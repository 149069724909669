import React from 'react'
import "../../assets/css/customer/forgotpassword.css";
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';



const ForgotPassword = () => {
    const [open, setOpen] = React.useState(false);
  return (
    <>
    <div className="ForgotPswrdContain">
        <div className="ForgotPswrdLogo">
            <img src={Logo} alt="img" />
        </div>
        <div className="ForgotPswrdCard">
            <div className="ForgotPswrdTitle">
                <h4>Forgot Password</h4>
            </div>
            <div className="ForgotPswrdGroup">
                <label htmlFor="id">PAN Card</label>
                <input type="text" placeholder='Enter Your PAN Card'/>
            </div>
            <div className="ForgotPswrdDivider"><span>OR</span></div>
            <div className="ForgotPswrdGroup">
                <label htmlFor="name">User Name</label>
                <input type="text" placeholder='Enter Your Name'/>
            </div>
            <div className={`ForgotPswrdGroup ${open === true ? '' : 'd-none'}`}>
                <label htmlFor="otp">OTP</label>
                <input type="text" placeholder='Enter Your OTP'/>
            </div>
            <div className="ForgotPswrdGroup ForgotPswrdBtn">
                <Link>
                <button onClick={() => setOpen(!open)} type='submit'>Send</button>
                </Link>
            </div>
            <div className="SignUpGroup signUp">
                <p>Already have an account? <Link to='/'>Login </Link></p>
            </div>
        </div>
    </div>
        <div className="ForgotPswrdFooter">
            <p>POWERED BY : <span>MANTHAN IT SOLUTIONS</span> </p>
        </div>
        </>
  )
}

export default ForgotPassword