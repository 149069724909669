import React, { useEffect, useState } from 'react';
import '../assets/css/components/header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ProfileIcon from "../assets/images/icons/profile1.png";
import logoutIcon from "../assets/images/icons/logout.png";
import { jwtDecode } from 'jwt-decode';
import { apiCall } from "../services/apiservices";
import { useUIContext } from '../context';

const Header = ({ title, arrowIcon, profileIconClass }) => {

  const [open, setOpen] = useState(false);
  const [profilePic, setProfileimage] = useState(ProfileIcon);
  const [LoginUserName, setLoginUserName] = useState("");
  const location = useLocation();
  const { setHome } = useUIContext();
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    

    // const token = localStorage.getItem('token');
    // if (token) {
    //   const parsedToken = jwtDecode(token);

    //   let customer_id = parsedToken.customerID
    //   let payloadata = { 'customer_id': customer_id }

      let response = await apiCall({ endpoint: "auth/profiledata", method: 'GET'})

      setLoginUserName(response.data[0].customer_name)
      setProfileimage(response.data[0].customer_profile);

    // }
  };

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      setHome("/admin/home");
    } else if (location.pathname.includes("/employe")) {
      setHome('/employe/home');
    } else {
      setHome('/home');
    }
  }, [location.pathname]);

  const handleClick = (event) => {
    const validHomes = ['/home', '/admin/home', '/employe/home'];
    if (validHomes.includes(location.pathname)) {
      event.preventDefault();
      setOpen(!open);
    } else {
      navigate(-1);
    }
  };

  return (
    <section id="Header" className="ContactHeader">
      <div className={profileIconClass === 'd-none' ? 'HeaderContain' : "HeaderContain ProfileHeader"}>
        <div className={profileIconClass === 'd-none' ? 'ProfileIcon' : "ProfileIcon bg_transparant"}>
          <Link onClick={handleClick}>
            <img src={location.pathname === "/home" || location.pathname === "/admin/home" || location.pathname === "/employe/home" ? profilePic : arrowIcon} alt="Back" />
          </Link>
        </div>
        <h2 className="HeaderTitle">{profileIconClass === 'd-none' ? 'Hi, ' : " "}{LoginUserName}</h2>
        <div className={`ProfileIcon ${profileIconClass || ''}`} onClick={() => setOpen(!open)}>
          <img src={profilePic} alt="Profile" />
        </div>
        <ul className={`LogoutCard ${profileIconClass === 'd-none' ? 'RightL' : ''} ${open === false ? 'd-none' : ''}`}>
          <li><Link to='/profile'><img src={profilePic} alt="icon" />Profile</Link></li>
          <li><Link to='/'><img src={logoutIcon} alt="icon" />Logout</Link></li>
        </ul>
      </div>
    </section>
  );
};

export default Header;
