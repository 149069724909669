import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import '../../assets/css/employe/employepending.css';
import arrowIcon from "../../assets/images/icons/arrow.png";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import requestIcon from "../../assets/images/icons/request.png";
import { apiCall } from '../../services/apiservices.jsx';

const EmployePending = () => {
  const { status } = useParams(); // Get the status from URL params
  const [currentStatus, setCurrentStatus] = useState(status || 'Pending');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStatus(status || 'Pending'); // Set default to "Pending" if the param is not specified
    fetchData(status || 'Pending');
  }, [status]);

  const fetchData = async (status) => {
    let fetchedData = [];
    const endPoint = `employee/getStatusWiseDataForToDoPage?status=${status}`;
    
    try {
      const res = await apiCall({ endpoint: endPoint, method: 'GET' });
      if (res.success) {
        fetchedData = res.data.statusWiseData || []; // Default to an empty array
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
    
    setData(fetchedData); // Avoid setting undefined
  };

  const handleStatusChange = async (event) => {
    const selectedStatus = event.target.value;
    setCurrentStatus(selectedStatus);
    fetchData(selectedStatus); // Fetch new data based on selected status
  };

  return (   
    <>
      <Header title={currentStatus} arrowIcon={arrowIcon} />
      <div className="ContentContainer">
        <div className="PendingHeader">
          <h4>{currentStatus} : <span>{data.length}</span></h4>
          <div className="PendingDropDown">
            <select name="pending" id="pending" value={currentStatus} onChange={handleStatusChange}>
              <option value="Pending">Pending</option>
              <option value="In-Process">In-Process</option>
              <option value="Resolved">Resolved</option>
            </select>
          </div>
        </div>
        {data.map((item, index) => (
          <div className="TrackDetails" key={index}>
            <div className="TrackMessageCard">
              <h4>{item.issue_type}</h4>
              <span className='CardCount'>{index + 1}</span>
            </div>
            <div className="row gy-3 mt-2">
              <div className="col-12">
                <div className="TrackMessageCard">
                  <h4>Ticket Id :  <span>{item.ticket_id}</span></h4>
                </div>
              </div>
              <div className="col-12">
                <div className="TrackMessageCard">
                  <h4>Date :  <span>{item.create_date}</span></h4>
                </div>
              </div>
              <div className="col-6">
                <div className="TrackMessageCard">
                  <h4>Age :  <span>{item.daysDifference}</span></h4>
                </div>
              </div>
              <div className="col-6">
                <div className="TrackMessageCard text-end">
                  <span className={currentStatus === 'In-Process' ? 'Colorblue' : currentStatus === 'Resolved' ? 'ColorGreen' : 'colorRed'}>{currentStatus}</span>
                </div>
              </div>
              <div className="col-12">
                <div className="TrackMessageCard MessageCard mt-0">
                  <h4>Remarks :</h4>
                  <p>{item.comment}</p>
                </div>
              </div>

              {currentStatus !== 'Resolved' && (
              <div className="col-12">
                <div className="TrackMessageCard">
                  <Link to="/employe/followup" state={{ id: item.ticket_id,cDate: item.create_date,issue_type: item.issue_type,comment:item.comment,createdTime:item.create_time,statusData:status}}>
                    <button className="RequestModalBtn">Action</button>
                  </Link>
                </div>
              </div>
               )}

            </div>
          </div>
        ))}
      </div>
      <Footer modifiedItem={{ index: 1, icon: requestIcon, label: currentStatus, link: `/employee/${currentStatus.toLowerCase()}` }} />
    </>
  );
};

export default EmployePending;
