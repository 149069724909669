import React from "react";
import "../../assets/css/admin/followup.css";
import Footer from "../../components/Footer";
import arrowIcon from "../../assets/images/icons/arrow.png";
import callNowIcon from "../../assets/images/icons/callnow.png";
import requestIcon from "../../assets/images/icons/request.png";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

const Followup = () => {
    const modifiedItem = {
      index: 1,
      icon: requestIcon,
      label: "Followup",
      link: "/admin/followup",
    };
    const status='In-Process'
    return (
      <>
      <Header title="Followup" arrowIcon={arrowIcon} />
        <div className="ContentContainer">
  
          <div className="FollowUpHeader mt-3 d-flex justify-content-between">
              <h4 >Ticket ID : <span>MIS-11102024-39246</span> </h4>
              
              <span className={`CardCount ${status === 'In-Process' ? 'Colorblue' : status === 'Resolved' ? 'ColorGreen' : 'colorRed'}`}>{status}</span>
          </div>

  
          <div className="TrackDetails RequestDetails">
            <div className="d-flex justify-content-between Flexwrp320">
            <div className="TicketDate TrackMessageCard">
              <h4>Ticket Date</h4>
              <p>03-10-2024 04:04:56</p>
            </div>
            <div className="TrackMessageCard Status">
              <h4>Status</h4>
              <select name="status" id="status">
              <option value="">In-Process</option>
                  <option value="Pending">Pending</option>
                  <option value="Resolved">Resolved</option>
              </select>
            </div>
            </div>
 
              <div className="row gy-3 pt-3">
                  <div className="col-7">
                      <div className="TrackMessageCard">
                        <h4>Category :</h4>
                        <p>Message Delivery Related Issue</p>
                      </div>
                  </div>
                  <div className="col-5 text-end">
                      <div className="TrackMessageCard">
                        <h4>Mobile :</h4>
                        <p>1234567890</p>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard FallowCard">
                          <h4>Customer Remarks : </h4>
                          <textarea name="comment" id="comment" value="Lorem ipsum dolor sit amet" readOnly></textarea>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard FallowCard">
                          <h4>Followup Remarks : </h4>
                          <textarea name="comment1" id="comment1" placeholder="Lorem ipsum dolor sit amet."></textarea>
                      </div>
                  </div>
                  <div className="col-12">
                      <div className="TrackMessageCard text-center FollowupBtnContain">
                      <Link to='/admin/followup/history'><button className="RequestModalBtn HistoryBtn" type="submit">History </button></Link>
                      <a href="tel:18001370808" target="_blank"><button className="RequestModalBtn CallNowBtn" type="submit"><span><img src={callNowIcon} alt="icon" /></span> Call Now </button></a>
                      <button className="RequestModalBtn" type="submit">Save </button>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <Footer modifiedItem={modifiedItem} />
      </>
    );
};

export default Followup;